.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  overflow: hidden;
  padding: 20px;

  background-color: #03c4a1;
}
